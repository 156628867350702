import { makeObservable, observable, action } from 'mobx';
import { getProductById } from '../api/backend';

class addToOrderStore {
  id = '';
  order = {};
  additionalPrice = 0;
  isCloudLite = false;
  cloudLiteKey = '';
  isSuperCustom = false;
  superCustomKey = '';
  isSuperDuperCustom = false;
  superDuperCustomKey = '';
  patURL = null;
  editState = 'aC1bC1cC1dC1e0f0g64h689F0089BF00ADDF00006000004004002004iFC';
  pccolrId = 'n4D';
  checkOrderValidationError = {};
  submitOrder = false;
  leadTime = null;
  qty = 1;
  productVariants = [];
  selectedVariant = {};
  orderAccent = 'none';
  confirmDefaultColor = true;
  isGetVariantsLoading = true;
  error = null;
  svgIdsToHide = [];
  svgIdsToShow = [];
  hasErrorValidation = false;
  itemMetaData = null;
  userHasMadeChanges = false;
  disabledAddToCart = false;

  constructor() {
    makeObservable(this, {
      additionalPrice: observable,
      checkOrderValidationError: observable,
      cloudLiteKey: observable,
      confirmDefaultColor: observable,
      disabledAddToCart: observable,
      error: observable,
      getCheckOrderValidationError: observable,
      getMetadata: observable,
      hasErrorValidation: observable,
      itemMetaData: observable,
      isCloudLite: observable,
      isGetVariantsLoading: observable,
      isSuperCustom: observable,
      isSuperDuperCustom: observable,
      leadTime: observable,
      order: observable,
      orderAccent: observable,
      patURL: observable,
      pccolrId: observable,
      productVariants: observable,
      qty: observable,
      selectedVariant: observable,
      submitOrder: observable,
      superCustomKey: observable,
      superDuperCustomKey: observable,
      svgIdsToHide: observable,
      svgIdsToShow: observable,
      userHasMadeChanges: observable,
      clearOrderValidationError: action,
      getAdditionalPrice: action,
      getProductVariants: action,
      loadLocalStorage: action,
      resetItemMetaData: action,
      resetMetadata: action,
      resetSuperCustom: action,
      saveLocalStorage: action,
      setAdditionalPrice: action,
      setCheckOrderValidationError: action,
      setCloudLite: action,
      setConfirmDefaultColor: action,
      setDisabledAddToCart: action,
      setEditState: action,
      setError: action,
      setHasErrorValidation: action,
      setIsGetVariantsLoading: action,
      setLeadTime: action,
      setMetadata: action,
      setOrderAccent: action,
      setPatURL: action,
      setPccolrId: action,
      setProductVariants: action,
      setQty: action,
      setSelectedVariant: action,
      setSuperCustom: action,
      setSuperDuperCustom: action,
      setSvgIdsToHide: action,
      setSvgIdsToShow: action,
      setSubmitOrder: action,
      setUserHasMadeChanges: action,
      unsetMetadata: action,
      unsetCheckOrderValidationError: action,
      updateItemMetaData: action,
    });
  }

  setLeadTime(time) {
    this.leadTime = time;
  }

  // Checking order validations
  setCheckOrderValidationError(fieldName, value) {
    this.checkOrderValidationError[fieldName] = value;
  }

  unsetCheckOrderValidationError(fieldName) {
    delete this.checkOrderValidationError[fieldName];
  }

  getCheckOrderValidationError(fieldName) {
    return this.checkOrderValidationError[fieldName];
  }

  setHasErrorValidation() {
    const { checkOrderValidationError } = this;
    const findError = Object.keys(checkOrderValidationError).find((key) => {
      return (
        checkOrderValidationError[key] === undefined ||
        checkOrderValidationError[key]
      );
    });

    this.hasErrorValidation = !!findError;
  }

  clearOrderValidationError() {
    this.checkOrderValidationError = {};
  }

  setSubmitOrder(value) {
    this.submitOrder = value;
  }
  setIsGetVariantsLoading(value) {
    this.isGetVariantsLoading = value;
  }

  // Saving order into localStorage
  loadLocalStorage(_id) {
    this.id = 'order_' + _id;
    this.order = JSON.parse(localStorage.getItem(this.id)) || {};
  }

  saveLocalStorage() {
    if (!this.id) return;
    localStorage.setItem(this.id, JSON.stringify(this.order));
  }

  // For SuperCustom
  setPatURL(val) {
    this.patURL = val;
  }

  setEditState(val) {
    this.editState = val;
  }

  setPccolrId(val) {
    this.pccolrId = val;
  }

  // SetDefault Color
  setConfirmDefaultColor(val) {
    this.confirmDefaultColor = val;
  }

  setOrderAccent(value) {
    this.orderAccent = value;
  }

  setQty(qty) {
    this.qty = qty;
  }

  async getProductVariants(productId, isDefaultCustom = true) {
    const variants = await getProductById(productId);
    this.setProductVariants(variants.data);

    const customVarian = variants.data.find((k) => k.sku.includes('-CUSTOM'));
    if (customVarian && isDefaultCustom) {
      this.setSelectedVariant(customVarian);
    } else {
      // Auto select first variant
      this.setSelectedVariant(variants.data[0]);
    }
  }

  // FIXME: There's a bug on the simple stache page where the first items is not correctly
  // being set as the selected variant on first initial page load
  setProductVariants(variants) {
    this.productVariants = variants;
  }

  setSelectedVariant(variant) {
    this.selectedVariant = variant;
  }

  resetMetadata() {
    this.order = {};
  }

  setMetadata(metadata, val) {
    this.order[metadata] = val;
    this.saveLocalStorage();
  }

  unsetMetadata(metadata) {
    delete this.order[metadata];
    this.saveLocalStorage();
  }

  getMetadata(metadata) {
    return this.order[metadata];
  }

  setAdditionalPrice(amount) {
    if (typeof this.order['total_price'] == 'undefined') {
      this.order['total_price'] = 0;
    }

    this.order['total_price'] += amount;
    this.saveLocalStorage();
  }
  getAdditionalPrice() {
    return this.order['total_price'] || 0;
  }
  setCloudLite(key, val) {
    this.cloudLiteKey = key;
    this.isCloudLite = val;
  }

  setSvgIdsToHide(ids) {
    this.svgIdsToHide = ids;
  }

  setSvgIdsToShow(ids) {
    this.svgIdsToShow = ids;
  }

  setSuperCustom(key, val) {
    this.superCustomKey = key;
    this.isSuperCustom = val;
  }

  setSuperDuperCustom(key, val) {
    this.superDuperCustomKey = key;
    this.isSuperDuperCustom = val;
  }

  resetItemMetaData() {
    this.itemMetaData = null;
  }

  resetSuperCustom() {
    this.patURL = null;
    delete this.order.child_super_custom_check;
    delete this.order.pc_edit_state;
    delete this.order.pc_pattern_url;
  }

  updateItemMetaData(key, val) {
    this.itemMetaData = { ...this.itemMetaData, [key]: val };
  }

  setError(key, value) {
    this.error = { ...this.error, [key]: value };
  }

  setUserHasMadeChanges(boolean) {
    this.userHasMadeChanges = boolean;
  }

  setDisabledAddToCart(boolean) {
    this.disabledAddToCart = boolean;
  }
}

export default addToOrderStore;
